<template>
    <div class="project">
        <h1>Connections Wall at Northwestern University</h1>
        <div class="topInfo">
            <p>
                When: 2016<br />
                Agency:
                <a href="http://www.potiondesign.com/">Potion Design</a> <br />
                Role: Lead Programmer
            </p>
        </div>
        <b-img class="media fade-in" src="@/assets/projects/connections-wall/winstonstruye.com-1440.jpg" fluid></b-img>
        <div class="content">
            <p>
                We created a 20x5 foot multi-touch, multi-user interactive wall for the Northwestern University Visitor
                Center. The nodes in the scene represent stories and 'hashtags', which provide information to
                prospective students and their families in a playful manner. The application is built as both a user
                interface filled with panels and scroll views, driven by a physics simulation based on fluids and a
                network of mass-springs.
            </p>
            <p>
                We installed the software at two locations: the main NYU campus in Evanston, IL, and their satellite
                space in San Francisco, CA.
            </p>
        </div>
        <b-embed
            class="media"
            type="iframe"
            aspect="16by9"
            allowfullscreen
            src="https://player.vimeo.com/video/252630187"
        ></b-embed>
        <div class="content">
            <h2>User Interface</h2>
            <p>
                The UI was built with
                <a href="https://github.com/richardeakin/Cinder-View">Cinder-View</a>, with this being an ideal use case
                for testing and developing its multi-touch abilities. The interface is designed so that nine people can
                interact with the application at the same time (one per portrait display). This was indeed how the
                interface ended up getting used many times directly before or after a talk in the visitor hall.
            </p>
            <p>
                When you tap on a node, a story panel appears containing an image / video carousel, some text
                paragraphs, and a smaller network of related story nodes. The image carousel is built as a custom
                <code>ScrollView</code>, where each page stacks on top of the other. The transition between pages ended
                up being quite fun to see; since there were no enforced size or aspect requirements the different image
                stacks each looked and animated a bit differently. The largest hurdle was in performance, as it was
                possible for all nine 1080p (max resolution) videos to be playing simultaneously, while users are
                scrolling through the carousel. There was not much of an ability to limit this since the content was CMS
                driven in Wordpress and the nodes are floating around the screen, meaning that any story with X number
                of videos in it could pop up at any time, on any screen. I ended up using a pool of video players that
                were built on Cinder-WMFPlayer, which solved most of the problems around UI hitches during video loads.
            </p>
            <p>
                To handle anti-aliasing at this high resolution while avoiding hardware MSAA, most things were rendered
                with signed distance fields (SDFs), like the simple geometric shapes (circles, rectangles, and lines
                connecting nodes). The text was also rendered using SDF's, and a static glyph map that was generated in
                a preprocess.
            </p>
            <p>
                We had many places with text on top of images and other aspects of the scene, so drop-shadows were a
                natural solution to make these more legible. For the story image carousel, I managed this with a custom
                shader since the pages are rectangular. For text, it's implemented as a Cinder-View Filter, which
                renders and caches an image of the drop-shadow for any semi-transparent content.
            </p>
        </div>
        <b-img class="media" src="@/assets/projects/connections-wall/wall_stories_open_halfres.jpg" fluid />
        <b-container>
            <b-row align-v="center">
                <b-col class="mb-2">
                    <b-img
                        class="media tripletImg"
                        src="@/assets/projects/connections-wall/story_orbiting_related.jpg"
                        fluid
                    />
                </b-col>
                <b-col class="mb-2">
                    <b-img
                        class="media tripletImg"
                        src="@/assets/projects/connections-wall/dropshadows_hashtag2.jpg"
                        fluid
                    />
                </b-col>
                <b-col class="mb-2">
                    <b-img
                        class="media tripletImg"
                        src="@/assets/projects/connections-wall/dropshadows_easteregg.jpg"
                        fluid
                    />
                </b-col>
            </b-row>
        </b-container>
        <div class="content">
            <h2>Node Physics, Movement and Placement</h2>
            <p>
                The main concept behind the scene was that of an aquarium filled with interlinked 'story nodes' you
                could interact with, evolving over time to present new information. I modeled the node connections as an
                N to N mass-spring network, where resting distances were fixed when a node was spawned. The physics
                update was implemented using OpenGL transform feedback on a fixed set of instanced geometry, which
                required limiting the number of connections in some way. A maximum of four links seemed to be enough,
                although for stability there is also an additional attribute for 'reverse links'.
            </p>
            <p>
                The node movement is driven by the velocity buffer of a 2D fluid simulation (I used Hai Nguyen's
                implementation <a href="https://github.com/chaoticbob/CinderFx">CinderFx</a>) applied to the 'surface
                level' of the nodes, or in other words what was closest to the screen. Touches are injected into the
                velocity field so that a person's swipe causes the nodes to sway in that direction, and also get pulled
                and rotated in 3D by their linked nodes.
            </p>
            <p>
                The nodes are managed spatially with a grid system that tallies whether there are two many nodes in a
                given area, and also whether there are too few of a specific type. When there are too many, a 'repelling
                magnet' is placed, which causes the nodes in that area to rotate around a randomized axis, outwards
                until they are off-screen. This caused a nice rolling effect, which really improved the 'underwater'
                feeling of the scene. If the nodes are really bunched up, I just kill them off by shooting the node
                backward in the Z direction, causing it to fade out in the fog. Similarly, if there aren't enough nodes
                of a given type within a region, a suitable node is spawned and faded in from far away along the z-axis,
                so that it fades in naturally. This spawning also happens for story nodes needed for the UI mode if
                there isn't a particular one close enough.
            </p>
            <h2>Combining the Physics Simulation and User Interface</h2>
            <p>
                The nodes live freely within the fluid simulation, yet they are also used to populate the Story and
                Category UI pannels. In order to move from the free-moving physics world into a usable interface,
                constraints were introduced to control the positions. When a node is marked as in use for a UI panel, it
                gets an anchor position, yet it is still allowed to act as it usually would within the simulation.
                Maximum and minimum positions are set that restrict the node to reasonable distortions within the UI,
                but they are otherwise still updated within the physics loop. Allowing the physics loop to always affect
                the nodes tied to the UI resulted in a quite fluid and playful experience, much more engaging compared to 
                most interfaces designed for web or other touch devices I've encountered.
            </p>
        </div>
        <b-img class="media" src="@/assets/projects/connections-wall/nw-wall-scaled.jpg" fluid />
        <b-img class="media" src="@/assets/projects/connections-wall/2016-06-08-11.39.44-scaled.jpg" fluid />
        <b-img class="media" src="@/assets/projects/connections-wall/2016-10-28-13.25.37-scaled.jpg" fluid />
        <div class="content">
            <p>
                More info on Potion's <a href="https://www.potiondesign.com/project/connections-wall/">project page</a>.<br>
                Made in C++ using <a href="https://www.libcinder.org/">libcinder</a>.
            </p>
        </div>
    </div>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped>

@import "../../assets/projects/style.scss";
@import "../../assets/common.scss";

.tripletImg {
    max-width: 20em;
}
</style>